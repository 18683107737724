import React from 'react'
import Container from 'react-bootstrap/Container'
import Plot from 'react-plotly.js'

export default class HistogramCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      thicknesses: [],
      max_thickness: 0,
      min_thickness: 0,
    }

    this.load_histogram = this.load_histogram.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return this.props.clicks !== nextProps.clicks
  }

  load_histogram() {
    const thicknesses = this.props.thicknesses
    const max_thickness = this.props.max_thickness
    const min_thickness = this.props.min_thickness

    if (thicknesses === [] || max_thickness === 0 || min_thickness === 0) {
      return <p>Loading...</p>
    } else {
      return (
        <Plot
          data={[
            {
              x: thicknesses,
              type: 'histogram',
              marker: {
                color: '#006967',
              },
              xbins: {
                size: 0.01,
                end: max_thickness,
                start: min_thickness,
              },
            },
          ]}
          useResizeHandler
          style={{ width: '100%', height: '100%' }}
          layout={{
            yaxis: { type: 'log', autorange: true },
            showlegend: false,
            autosize: true,
            margin: {
              l: 50,
              r: 50,
              b: 50,
              t: 50,
              // pad: 4,
            },
          }}
          config={{
            // responsive: true,
            modeBarButtonsToRemove: [
              'zoomIn2d',
              'zoomOut2d',
              'select2d',
              'lasso2d',
              'autoScale2d',
              'pan2d',
              'zoom2d',
            ],
            displaylogo: false,
          }}></Plot>
      )
    }
  }

  render() {
    let histogram = this.load_histogram()

    return (
      <div>
        <Container className='Histogram-card' fluid>
          <header className='Histogram-card-header'></header>
          <h5 className='histogram-title text-primary'>Thickness Distribution</h5>
          <div style={{ width: '100%', height: '100%' }}>{histogram}</div>
        </Container>
      </div>
    )
  }
}
