import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export default class StatisticsCard extends React.Component {
  constructor(props) {
    super(props)

    this.load_nominal = this.load_nominal.bind(this)
    this.handleCustomNominal = this.handleCustomNominal.bind(this)
    this.load_thicknesses = this.load_thicknesses.bind(this)
  }

  load_thicknesses() {
    const low_thickness = this.props.initial_minimum
    const high_thickness = this.props.initial_maximum
    if (low_thickness === '' || high_thickness === '') {
      return <b>Loading ....</b>
    } else {
      return (
        <b>
          {low_thickness}-{high_thickness}"
        </b>
      )
    }
  }

  load_nominal() {
    const nominal = this.props.estimated_nominal

    if (nominal === '') {
      return <b>Loading ....</b>
    } else {
      return <b>{nominal}"</b>
    }
  }

  handleCustomNominal(event) {
    this.props.custom_nominal_function(event.target.value)
  }

  render() {
    //Load the nominal
    let nominal_text = this.load_nominal()
    let range = this.load_thicknesses()

    return (
      <div>
        <Container className='Statistics-card' fluid>
          <header className='Statistics-card-header'>
            <Row>
              <Col xs={4}>
                <p className='thickness-range text-primary'>Range:</p>
              </Col>
              <Col className='stats-text' xs={8}>
                {range}
              </Col>
            </Row>
            <Row>
              <Col xs={7}>
                <p className='estimated-nominal text-primary'>Estimated Nominal:</p>
              </Col>
              <Col className='stats-text' xs={5}>
                {nominal_text}
              </Col>
            </Row>
            <label>Enter Custom Nominal</label>
            <input
              id='custom-nominal'
              xs={6}
              className='Col-main'
              type='number'
              min={0}
              step={0.005}
              onChange={this.handleCustomNominal}></input>
          </header>
        </Container>
      </div>
    )
  }
}
