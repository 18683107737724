import { Layout as AntdLayout } from 'antd'
import { Layout } from 'components/Layout'
import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Attributes } from './Attributes'
import Choose3DFile from './Choose3DFile'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useAuth0, GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { Auth0Audience } from '../api/helpers'

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div role='alert' className='text-center'>
      <h3 className='mt-2 text-sm font-medium text-gray-900'>There was an error:</h3>
      {/* @ts-ignore */}
      <p className='mt-1 text-sm text-gray-500'>{error?.message?.message}</p>
      <div className='mt-6'>
        <button
          type='button'
          onClick={resetErrorBoundary}
          className='inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-gecko_brand-600_base hover:bg-gecko_brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gecko_brand-500'>
          Go back
        </button>
      </div>
    </div>
  )
}

function LoggedInContent() {
  const { getAccessTokenSilently } = useAuth0()

  const [tokenBol, setTokenBol] = React.useState<boolean>(false)
  const [token_string, setToken] = React.useState<string>('hello')

  const getToken = async (
    getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>
  ) => {
    const options = { audience: Auth0Audience() }
    const token = await getAccessTokenSilently(options)
    setToken(token)
    setTokenBol(true)
  }

  React.useEffect(() => {
    getToken(getAccessTokenSilently)
  }, [])

  if (tokenBol) {
    return <Choose3DFile token={token_string}></Choose3DFile>
  } else {
    return <p>Loading...</p>
  }
}

export default LoggedInContent
