import { ReactComponent as XIcon } from 'assets/x-icon.svg'
import React from 'react'

export const ErrorBanner: React.FC<{ showBanner?: boolean; errorMessage?: any }> = (
  props
) => {
  const [showBanner, setShowBanner] = React.useState(props?.showBanner ?? false)

  const handleDismiss = () => {
    setShowBanner(false)
  }
  React.useEffect(() => {
    if (props?.showBanner !== undefined) {
      setShowBanner(props?.showBanner)
    }
    return () => {
      setShowBanner(false)
    }
  }, [props?.showBanner])

  return (
    <>
      {showBanner ? (
        <div className='fixed inset-x-0 top-0 z-30 pb-2 sm:pb-5'>
          <div className='px-2 mx-auto max-w-7xl sm:px-6 lg:px-8 '>
            <div className='p-2 bg-red-600 rounded-lg shadow-lg sm:p-3 '>
              <div className='flex items-center justify-between w-full text-center '>
                <div className='w-full pr-16 text-lg text-center sm:px-16'>
                  <p className='font-medium text-white'>
                    <span className='md:hidden'>There was an error!</span>
                    <span className='hidden md:inline'>
                      {props.errorMessage ? props.errorMessage : 'There was an error'}
                    </span>
                  </p>
                </div>
                <div className='flex-shrink-0 order-2 sm:order-3 sm:ml-2'>
                  <button
                    type='button'
                    onClick={handleDismiss}
                    className='flex p-2 -mr-1 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white'>
                    <span className='sr-only'>Dismiss</span>
                    <XIcon
                      className='w-6 h-6 text-white fill-current'
                      aria-hidden='true'
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
