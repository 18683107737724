import React from 'react'
import { ReactComponent as GeckoLogo } from 'assets/gecko-logo.svg'

function LoadingContent() {
  return (
    <div className='flex flex-col w-full h-[100vh] items-center justify-center'>
      <GeckoLogo className='w-32 h-32 mb-4 ' /> Loading...
    </div>
  )
}

export default LoadingContent
