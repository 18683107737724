import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

function LoginButton() {
  const { loginWithRedirect } = useAuth0()

  return (
    <div>
      <button
        onClick={() => loginWithRedirect()}
        className='relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md group bg-gecko_brand-600_base hover:bg-gecko_brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gecko_brand-500'>
        <span className='absolute inset-y-0 left-0 flex items-center pl-3'></span>
        Log in
      </button>
    </div>
  )
}

export default LoginButton
