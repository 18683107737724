import React from 'react'
import { use3dFiles } from 'api/backend'
import { components } from '../util/schema'
import { Card } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import { useAuth0, GetTokenSilentlyOptions } from '@auth0/auth0-react'
import Oracle3D from './Oracle3D'

export default class Choose3DFile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      chosen_file: '',
      file_chosen_bol: false,
    }
  }

  componentDidMount() {
    const data = JSON.stringify({ tags: {} })

    fetch(
      'https://object-service.dev.cloud.geckorobotics.com/api/v1/files/search/3d-ascans',
      {
        headers: {
          // Add the Authorization header to the existing headers
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        },
        body: data,
        method: 'POST',
      }
    )
      .then((data) => data.json())
      .then((data) => {
        var files = []

        for (var i = 0; i < data.length; i++) {
          files.push(data[i]['id'])
        }
        this.setState({
          files: files,
        })
      })
  }

  handleSelect = (e) => {
    const chosen_file = e.target.innerHTML
    this.setState({
      chosen_file: chosen_file,
      file_chosen_bol: true,
    })
  }

  routeToOracle() {
    return (
      <Oracle3D file_name={this.state.chosen_file} token={this.props.token}></Oracle3D>
    )
  }

  load_dropdown() {
    const files = Array.from(this.state.files)
    if (files.length === 0) {
      return (
        <div className='choose-3d-file-dropdown-div'>
          <Card>
            <Card.Body className='dropdown-card'>
              <div className='dropdown-div'>
                <Dropdown className='file-dropdown bg-danger'>
                  <Dropdown.Toggle variant='success' id='dropdown-basic'>
                    Loading Files...
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item key='loading'>Loading...</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Card.Body>
          </Card>
        </div>
      )
    } else {
      return (
        <div className='choose-3d-file-dropdown-div'>
          <Card>
            <Card.Body className='dropdown-card'>
              <div className='dropdown-div'>
                <Dropdown className='file-dropdown bg-primary'>
                  <Dropdown.Toggle variant='success' id='dropdown-basic'>
                    Choose a 3D File
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {files.map((file) => (
                      <Dropdown.Item
                        // href={file}
                        key={file}
                        onClick={this.handleSelect}>
                        {file}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Card.Body>
          </Card>
        </div>
      )
    }
  }

  render() {
    let load_dropdown = this.load_dropdown()
    let routeToOracle = this.routeToOracle()
    return <div>{this.state.file_chosen_bol ? routeToOracle : load_dropdown}</div>
  }
}
