import React, { useRef } from 'react'
import Container from 'react-bootstrap/Container'
import Plot from 'react-plotly.js'

export default class ScatterPlotCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      x: [],
      y: [],
      z: [],
      thickness: [],
      colors: [],
      refresh_clicks: 0,
      x_max: 0,
      x_min: 0,
      y_max: 0,
      y_min: 0,
      z_max: 0,
      z_min: 0,
      measure_clicks: 0,
      reset_measure_clicks: 0,
    }
    this.load_scatter = this.load_scatter.bind(this)
    this.pointClick = this.pointClick.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.refresh_clicks !== nextProps.refresh_clicks ||
      this.props.measure_clicks !== nextProps.measure_clicks ||
      this.props.reset_measure_clicks !== nextProps.reset_measure_clicks
    )
  }

  pointClick(event) {
    const x = event.points[0].x
    const y = event.points[0].y
    const z = event.points[0].z
    const point = [x, y, z]
    this.props.point_handler(point)
  }

  load_scatter() {
    const thickness = this.props.thickness
    const x = this.props.x
    const y = this.props.y
    const z = this.props.z
    const trace_x = this.props.trace_x
    const trace_y = this.props.trace_y
    const trace_z = this.props.trace_z
    const color = this.props.colors
    var data

    if (this.props.measure) {
      data = [
        {
          x: x,
          y: y,
          z: z,
          mode: 'markers',
          marker: {
            size: 5,
            color: color,
          },
          type: 'scatter3d',
          hovertemplate:
            '<br>Thickness=%{text:.3f}"' +
            "<br>X=%{x:.3f}'<br>Y=%{y:.3f}'<br>Z=%{z:.3f}'<extra></extra>",
          text: this.props.thickness,
        },
        {
          type: 'scatter3d',
          x: trace_x,
          y: trace_y,
          z: trace_z,
          mode: 'lines',
          opacity: 1,
          line: {
            width: 6,
            color: 'blue',
          },
        },
      ]
    } else {
      data = [
        {
          x: x,
          y: y,
          z: z,
          mode: 'markers',
          marker: {
            size: 5,
            color: color,
          },
          type: 'scatter3d',
          hovertemplate:
            '<br>Thickness=%{text:.3f}"' +
            "<br>X=%{x:.3f}'<br>Y=%{y:.3f}'<br>Z=%{z:.3f}'<extra></extra>",
          text: this.props.thickness,
        },
      ]
    }

    if (
      thickness.length === 0 ||
      x.length === 0 ||
      y.length === 0 ||
      z.length === 0 ||
      color === []
    ) {
      return <p>Loading...</p>
    } else {
      return (
        <Plot
          data={data}
          onClick={this.pointClick}
          useResizeHandler
          style={{ width: '100%', height: '100%' }}
          layout={{
            // xaxis: { range: [Math.min(...x), Math.max(...x)] },
            // yaxis: { range: [Math.min(...y), Math.max(...y)] },
            // zaxis: { range: [Math.min(...z), Math.max(...z)] },
            showlegend: false,
            uirevision: true,
            autosize: true,
            margin: {
              l: 0,
              r: 0,
              b: 0,
              t: 0,
              // pad: 4,
            },
          }}
          config={{
            responsive: true,
            modeBarButtonsToRemove: ['resetCameraLastSave3d'],
            displaylogo: false,
          }}></Plot>
      )
    }
  }

  render() {
    let scatter = this.load_scatter()
    return (
      <div>
        <Container className='Scatter-plot-card' fluid>
          <header className='Scatter-plot-card-header'></header>
          <div style={{ width: '100%', height: '100%' }}>{scatter}</div>
        </Container>
      </div>
    )
  }
}
