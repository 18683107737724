import React from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

export default class RedrawGraph extends React.Component {
  constructor(props) {
    super(props)

    this.refreshPage = this.refreshPage.bind(this)
  }

  refreshPage() {
    window.location.reload(false)
  }

  render() {
    return (
      <div>
        <Container className='Refresh-graph-card' fluid>
          <header className='Refresh-graph-card-header'></header>

          <div className='reset-graph-container'>
            <Button className='reset-graph-button bg-danger' onClick={this.props.reset}>
              Reset Graph
            </Button>
          </div>
          <div className='redraw-graph-container'>
            <Button
              className='refresh-graph-button bg-primary'
              onClick={this.props.refresh}>
              Update Graph
            </Button>
          </div>
        </Container>
      </div>
    )
  }
}
