import React from 'react'
import Container from 'react-bootstrap/Container'
import { Button } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class MeasureCard extends React.Component {
  constructor(props) {
    super(props)

    this.distanceDisplay = this.distanceDisplay.bind(this)
  }

  distanceDisplay() {
    return (
      <Row className='distance-text-row'>
        <Col xs={8}>
          <p className='distance-text text-primary'>Distance:</p>
        </Col>
        <Col className='actual-distance-text' xs={4}>
          {this.props.distance.toFixed(2)}'
        </Col>
      </Row>
    )
  }
  render() {
    let distance = this.distanceDisplay()
    return (
      <div>
        <Container className='Measure-card' fluid>
          <header className='Measure-card-header'>
            <h5 className='measure-distance text-primary'>Measure Distance</h5>
            <Button
              className='measure-button bg-primary'
              onClick={this.props.measure_function}>
              Measure Distance
            </Button>
            {distance}
            <Button
              className='measure-button bg-primary'
              onClick={this.props.reset_measure_function}>
              Reset Distance
            </Button>
          </header>
        </Container>
      </div>
    )
  }
}
