import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RangeSlider from 'react-range-slider-input/dist/components/RangeSlider'

export default class ColorSliderCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minimum_color: '',
      maximum_color: '',
      initial_minimum: '',
      initial_maximum: '',
    }

    this.load_minimum_color = this.load_minimum_color.bind(this)
    this.load_maximum_color = this.load_maximum_color.bind(this)
    this.load_initial_minimum = this.load_initial_minimum.bind(this)
    this.load_initial_maximum = this.load_initial_maximum.bind(this)
    this.load_range_slider = this.load_range_slider.bind(this)
    this.handleLowColor = this.handleLowColor.bind(this)
    this.handleHighColor = this.handleHighColor.bind(this)
  }

  handleLowColor(event) {
    this.props.low_color_function(event.target.value)
  }

  handleHighColor(event) {
    this.props.high_color_function(event.target.value)
  }

  load_minimum_color() {
    const minimum_color = this.props.minimum_color

    if (minimum_color === '' || minimum_color === null) {
      return ''
    } else {
      return minimum_color
    }
  }

  load_initial_minimum() {
    const initial_minimum = this.props.initial_minimum

    if (initial_minimum === '' || initial_minimum === null) {
      return ''
    } else {
      return initial_minimum
    }
  }

  load_initial_maximum() {
    const initial_maximum = this.props.initial_maximum

    if (initial_maximum === '' || initial_maximum === null) {
      return ''
    } else {
      return initial_maximum
    }
  }

  load_range_slider() {
    const maximum = this.props.maximum_color
    const minimum = this.props.minimum_color

    if (minimum === '' || maximum === '') {
      return ''
    } else {
      return (
        <RangeSlider
          min={minimum}
          max={maximum}
          step={0.001}
          defaultValue={[minimum, maximum]}></RangeSlider>
      )
    }
  }

  load_maximum_color() {
    const maximum_color = this.props.maximum_color

    if (maximum_color === '' || maximum_color === null) {
      return ''
    } else {
      return maximum_color
    }
  }

  render() {
    let minimum = this.load_minimum_color()
    let maximum = this.load_maximum_color()
    let range_slider = this.load_range_slider()
    let initial_maximum = this.load_initial_maximum()
    let initial_minimum = this.load_initial_minimum()
    return (
      <div>
        <Container className='Color-card' fluid>
          <header className='Color-card-header'>
            <h5 className='color-filter text-primary'>Color Filter</h5>
            {/* {range_slider} */}
            <Row>
              <Col>
                <label>Min</label>
                <input
                  id='min-color'
                  xs={6}
                  className='Col-main'
                  type='number'
                  value={minimum}
                  min={initial_minimum}
                  step={0.001}
                  onChange={this.handleLowColor}></input>
              </Col>
              <Col>
                <label>Max</label>
                <input
                  id='max-color'
                  xs={6}
                  className='Col-main'
                  type='number'
                  value={maximum}
                  max={initial_maximum}
                  step={0.001}
                  onChange={this.handleHighColor}></input>
              </Col>
            </Row>
          </header>
        </Container>
      </div>
    )
  }
}
