import './App.css'
import React from 'react'
import Content from './pages/Content'
import { SWRConfig } from 'swr'
import { BrowserRouter as Router } from 'react-router-dom'
import { ErrorBanner } from 'components/ErrorBanner'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  const [showBanner, setShowBanner] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState()

  return (
    <SWRConfig
      value={{
        onSuccess: (data, key, config) => {
          setShowBanner(false)
          setErrorMessage(undefined)
        },
        onError: (error, key) => {
          setShowBanner(true)
          setErrorMessage(error.message)
        },
      }}>
      <Router>
        <ErrorBanner showBanner={showBanner} errorMessage={errorMessage} />
        <div className=' bg-slate-200 h-[100vh]'>
          <Content />
        </div>
      </Router>
    </SWRConfig>
  )
}

export default App
