import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import LoggedInContent from './LoggedInContent'
import LoggedOutContent from './LoggedOutContent'
import LoadingContent from './LoadingContent'

function Content() {
  const { isLoading, isAuthenticated } = useAuth0()

  if (isLoading) {
    return <LoadingContent />
  }
  if (isAuthenticated) {
    return <LoggedInContent />
  }
  return <LoggedOutContent />
}

export default Content
