import React from 'react'
// import React from "react";
// import ReactDOM from "react-dom";
// import "./index.css";
import ColorSliderCard from '../components/ColorSliderCard'
import HistogramCard from '../components/HistogramCard'
import MeasureCard from '../components/MeasureCard'
import ScatterPlotCard from '../components/ScatterPlotCard'
import StatisticsCard from '../components/StatisticsCard'
import ThicknessSliderCard from '../components/ThicknessSliderCard'
import RedrawGraph from '../components/RedrawGraphCard'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'bootstrap/dist/css/bootstrap.min.css'
import Card from 'react-bootstrap/Card'
import '../index.scss'

export default class Oracle3D extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      estimated_nominal: '',
      custom_nominal: '',
      minimum_thickness: '',
      maximum_thickness: '',
      initial_minimum_color: '',
      initial_maximum_color: '',
      minimum_color: '',
      maximum_color: '',
      initial_minimum_thickness: '',
      initial_maximum_thickness: '',
      x: [],
      y: [],
      z: [],
      thickness: [],
      colors: [],
      refresh_clicks: 0,
      point1: [],
      point2: [],
      measure_clicks: 0,
      reset_measure_clicks: 0,
      refresh: false,
      measure: false,
      reset_measure: false,
      measure_distance: 0,
      trace_x: [],
      trace_y: [],
      trace_z: [],
    }

    this.colorLowUpdate = this.colorLowUpdate.bind(this)
    this.colorHighUpdate = this.colorHighUpdate.bind(this)
    this.thicknessLowUpdate = this.thicknessLowUpdate.bind(this)
    this.thicknessHighUpdate = this.thicknessHighUpdate.bind(this)
    this.refreshGraph = this.refreshGraph.bind(this)
    this.customNominalUpdate = this.customNominalUpdate.bind(this)
    this.wallLossButtonRefresh = this.wallLossButtonRefresh.bind(this)
    this.pointHandler = this.pointHandler.bind(this)
    this.measureDistance = this.measureDistance.bind(this)
    this.resetMeasure = this.resetMeasure.bind(this)
    this.resetGraph = this.resetGraph.bind(this)
  }

  componentDidMount() {
    // Fetch nominal
    fetch(
      `https://oracle-3d-backend-service.dev.cloud.geckorobotics.com/api/v1/stats/${this.props.file_name}`,
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        let nominal = JSON.parse(data).nominal
        let minimum_thickness = JSON.parse(data).minimum_thickness
        let maximum_thickness = JSON.parse(data).maximum_thickness

        this.setState({
          estimated_nominal: nominal,
          minimum_thickness: minimum_thickness,
          maximum_thickness: maximum_thickness,
          initial_minimum_color: minimum_thickness,
          initial_maximum_color: maximum_thickness,
          minimum_color: minimum_thickness,
          maximum_color: maximum_thickness,
          initial_maximum_thickness: maximum_thickness,
          initial_minimum_thickness: minimum_thickness,
        })
      })

    fetch(
      `https://oracle-3d-backend-service.dev.cloud.geckorobotics.com/api/v1/graphing/initial_data/${this.props.file_name}`,
      {
        headers: {
          // Add the Authorization header to the existing headers
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        let sensor_x = JSON.parse(data).map((item) => item.sensor_x)
        let sensor_y = JSON.parse(data).map((item) => item.sensor_y)
        let sensor_z = JSON.parse(data).map((item) => item.sensor_z)
        let thickness = JSON.parse(data).map((item) => item.thickness_bootsy)
        let color = JSON.parse(data).map((item) => item.final_color)

        this.setState((state) => {
          return {
            x: sensor_x,
            y: sensor_y,
            z: sensor_z,
            thickness: thickness,
            colors: color,
            refresh_clicks: state.refresh_clicks + 1,
          }
        })
      })
  }

  resetGraph() {
    fetch(
      `https://oracle-3d-backend-service.dev.cloud.geckorobotics.com/api/v1/graphing/initial_data/${this.props.file_name}`,
      {
        headers: {
          // Add the Authorization header to the existing headers
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        let sensor_x = JSON.parse(data).map((item) => item.sensor_x)
        let sensor_y = JSON.parse(data).map((item) => item.sensor_y)
        let sensor_z = JSON.parse(data).map((item) => item.sensor_z)
        let thickness = JSON.parse(data).map((item) => item.thickness_bootsy)
        let color = JSON.parse(data).map((item) => item.final_color)

        this.setState((state) => {
          return {
            custom_nominal: '',
            minimum_thickness: state.initial_minimum_thickness,
            maximum_thickness: state.initial_maximum_thickness,
            minimum_color: state.initial_minimum_thickness,
            maximum_color: state.initial_maximum_thickness,

            x: sensor_x,
            y: sensor_y,
            z: sensor_z,
            thickness: thickness,
            colors: color,
            refresh_clicks: state.refresh_clicks + 1,
          }
        })
      })
  }

  refreshGraph() {
    fetch(
      `https://oracle-3d-backend-service.dev.cloud.geckorobotics.com/api/v1/graphing/color_mapper/${this.props.file_name}/` +
        this.state.minimum_thickness +
        '/' +
        this.state.maximum_thickness +
        '/' +
        this.state.minimum_color +
        '/' +
        this.state.maximum_color,
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        let sensor_x = JSON.parse(data).map((item) => item.sensor_x)
        let sensor_y = JSON.parse(data).map((item) => item.sensor_y)
        let sensor_z = JSON.parse(data).map((item) => item.sensor_z)
        let thicknesses = JSON.parse(data).map((item) => item.thickness_bootsy)
        let color = JSON.parse(data).map((item) => item.final_color)

        this.setState((state) => {
          return {
            x: sensor_x,
            y: sensor_y,
            z: sensor_z,
            thickness: thicknesses,
            colors: color,
            refresh_clicks: state.refresh_clicks + 1,
            refresh: true,
            measure: false,
            reset_measure: false,
          }
        })
      })
  }

  wallLossButtonRefresh(thickness) {
    fetch(
      `https://oracle-3d-backend-service.dev.cloud.geckorobotics.com/api/v1/graphing/color_mapper/${this.props.file_name}/` +
        this.state.minimum_thickness +
        '/' +
        thickness +
        '/' +
        this.state.minimum_color +
        '/' +
        this.state.maximum_color,
      {
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((data) => data.json())
      .then((data) => {
        let sensor_x = JSON.parse(data).map((item) => item.sensor_x)
        let sensor_y = JSON.parse(data).map((item) => item.sensor_y)
        let sensor_z = JSON.parse(data).map((item) => item.sensor_z)
        let thicknesses = JSON.parse(data).map((item) => item.thickness_bootsy)
        let color = JSON.parse(data).map((item) => item.final_color)

        this.setState((state) => {
          return {
            x: sensor_x,
            y: sensor_y,
            z: sensor_z,
            thickness: thicknesses,
            colors: color,
            refresh_clicks: state.refresh_clicks + 1,
            refresh: true,
            measure: false,
            reset_measure: false,
            maximum_thickness: thickness,
          }
        })
      })
  }

  pointHandler(point) {
    if (this.state.point1 === []) {
      this.setState({ point1: point })
    } else {
      this.setState((state) => {
        return {
          point1: point,
          point2: state.point1,
        }
      })
    }
  }

  measureDistance() {
    if (this.state.point1 != [] && this.state.point2 != []) {
      fetch(
        `https://oracle-3d-backend-service.dev.cloud.geckorobotics.com/api/v1/graphing/distance/${this.props.file_name}/` +
          this.state.point1.toString() +
          '/' +
          this.state.point2.toString(),
        {
          headers: {
            Authorization: `Bearer ${this.props.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
        .then((data) => data.json())
        .then((data) => {
          this.setState((state) => {
            return {
              measure_clicks: state.measure_clicks + 1,
              refresh: false,
              measure: true,
              reset_measure: false,
              trace_x: JSON.parse(data).x_spline,
              trace_y: JSON.parse(data).y_spline,
              trace_z: JSON.parse(data).z_spline,
              measure_distance: JSON.parse(data).distance,
            }
          })
        })
    } else {
      this.setState((state) => {
        return {
          // measure_clicks: state.measure_clicks + 1,
          refresh: false,
          measure: false,
          reset_measure: false,
        }
      })
    }
  }

  resetMeasure() {
    this.setState((state) => {
      return {
        reset_measure_clicks: state.reset_measure_clicks + 1,
        refresh: false,
        measure: false,
        reset_measure: true,
        measure_distance: 0,
      }
    })
  }

  colorLowUpdate(thickness) {
    this.setState({ minimum_color: thickness })
  }

  colorHighUpdate(thickness) {
    this.setState({ maximum_color: thickness })
  }

  thicknessLowUpdate(thickness) {
    this.setState({ minimum_thickness: thickness })
  }

  thicknessHighUpdate(thickness) {
    this.setState({ maximum_thickness: thickness })
  }

  customNominalUpdate(nominal) {
    this.setState({ custom_nominal: nominal })
  }

  render() {
    const estimated_nominal = this.state.estimated_nominal
    const custom_nominal = this.state.custom_nominal
    const minimum_thickness = this.state.minimum_thickness
    const maximum_thickness = this.state.maximum_thickness
    const minimum_color = this.state.minimum_color
    const maximum_color = this.state.maximum_color
    const initial_minimum = this.state.initial_minimum_thickness
    const initial_maximum = this.state.initial_maximum_thickness
    const thicknesses = this.state.thickness
    const x = this.state.x
    const y = this.state.y
    const z = this.state.z
    const colors = this.state.colors
    const clicks = this.state.refresh_clicks
    const measure_clicks = this.state.measure_clicks
    const reset_measure_clicks = this.state.reset_measure_clicks
    const refresh = this.state.refresh
    const measure = this.state.measure
    const reset_measure = this.state.reset_measure
    const measure_distance = this.state.measure_distance
    const trace_x = this.state.trace_x
    const trace_y = this.state.trace_y
    const trace_z = this.state.trace_z

    return (
      <div>
        <Container fluid>
          <header className='App-header'>
            <Row>
              <Col xs={2} className='Col-main'>
                <Card>
                  <Card.Body>
                    <RedrawGraph
                      refresh={this.refreshGraph}
                      reset={this.resetGraph}></RedrawGraph>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <ColorSliderCard
                      maximum_color={maximum_color}
                      minimum_color={minimum_color}
                      low_color_function={this.colorLowUpdate}
                      high_color_function={this.colorHighUpdate}
                      initial_minimum={initial_minimum}
                      initial_maximum={initial_maximum}
                      estimated_nominal={estimated_nominal}></ColorSliderCard>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <ThicknessSliderCard
                      maximum_thickness={maximum_thickness}
                      minimum_thickness={minimum_thickness}
                      low_thickness_function={this.thicknessLowUpdate}
                      high_thickness_function={this.thicknessHighUpdate}
                      initial_minimum={initial_minimum}
                      initial_maximum={initial_maximum}
                      estimated_nominal={estimated_nominal}
                      custom_nominal={custom_nominal}
                      refresh={this.wallLossButtonRefresh}></ThicknessSliderCard>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <MeasureCard
                      measure_function={this.measureDistance}
                      reset_measure_function={this.resetMeasure}
                      distance={measure_distance}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={8} className='Col-main'>
                <Card>
                  <Card.Body>
                    <ScatterPlotCard
                      x={x}
                      y={y}
                      z={z}
                      thickness={thicknesses}
                      colors={colors}
                      refresh_clicks={clicks}
                      point_handler={this.pointHandler}
                      measure_clicks={measure_clicks}
                      reset_measure_clicks={reset_measure_clicks}
                      refresh={refresh}
                      measure={measure}
                      reset_measure={reset_measure}
                      measure_distance={measure_distance}
                      trace_x={trace_x}
                      trace_y={trace_y}
                      trace_z={trace_z}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={2} className='Col-main'>
                <Card>
                  <Card.Body>
                    <StatisticsCard
                      estimated_nominal={estimated_nominal}
                      custom_nominal_function={this.customNominalUpdate}
                      initial_minimum={initial_minimum}
                      initial_maximum={initial_maximum}></StatisticsCard>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    <HistogramCard
                      thicknesses={thicknesses}
                      max_thickness={maximum_thickness}
                      min_thickness={minimum_thickness}
                      clicks={clicks}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </header>
        </Container>
      </div>
    )
  }
}
