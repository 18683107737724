import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RangeSlider from 'react-range-slider-input/dist/components/RangeSlider'
import Button from 'react-bootstrap/Button'

export default class ThicknessSliderCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      minimum_thickness: '',
      maximum_thickness: '',
      initial_minimum: '',
      initial_maximum: '',
      nominal: '',
      custom_nominal: '',
    }

    this.load_minimum_thickness = this.load_minimum_thickness.bind(this)
    this.load_maximum_thickness = this.load_maximum_thickness.bind(this)
    this.load_initial_minimum = this.load_initial_minimum.bind(this)
    this.load_initial_maximum = this.load_initial_maximum.bind(this)
    this.load_range_slider = this.load_range_slider.bind(this)
    this.handleLowThickness = this.handleLowThickness.bind(this)
    this.handleHighThickness = this.handleHighThickness.bind(this)
    this.handleWallLossButton = this.handleWallLossButton.bind(this)
  }

  handleLowThickness(event) {
    this.props.low_thickness_function(event.target.value)
  }

  handleHighThickness(event) {
    this.props.high_thickness_function(event.target.value)
  }

  load_initial_minimum() {
    const initial_minimum = this.props.initial_minimum

    if (initial_minimum === '' || initial_minimum === null) {
      return ''
    } else {
      return initial_minimum
    }
  }

  load_range_slider() {
    const maximum = this.props.maximum_thickness
    const minimum = this.props.minimum_thickness

    if (minimum === '' || maximum === '') {
      return ''
    } else {
      return (
        <RangeSlider
          min={minimum}
          max={maximum}
          step={0.001}
          defaultValue={[minimum, maximum]}></RangeSlider>
      )
    }
  }

  load_initial_maximum() {
    const initial_maximum = this.props.initial_maximum

    if (initial_maximum === '' || initial_maximum === null) {
      return ''
    } else {
      return initial_maximum
    }
  }

  load_minimum_thickness() {
    const minimum_thickness = this.props.minimum_thickness

    if (minimum_thickness === '' || minimum_thickness === null) {
      return ''
    } else {
      return minimum_thickness
    }
  }

  load_maximum_thickness() {
    const maximum_thickness = this.props.maximum_thickness

    if (maximum_thickness === '' || maximum_thickness === null) {
      return ''
    } else {
      return maximum_thickness
    }
  }

  load_wall_loss() {
    const nominal = this.props.estimated_nominal
    const max_thickness = this.props.maximum_thickness
    const custom_nominal = this.props.custom_nominal

    if (nominal === '' || nominal === null) {
      return ''
    } else {
      if (custom_nominal === '' || custom_nominal === 0) {
        const loss = nominal - max_thickness
        let loss_percentage = (loss / nominal) * 100
        loss_percentage = loss_percentage.toFixed(2)
        if (loss_percentage > 0) {
          return <b>{loss_percentage}</b>
        } else {
          return <b>0</b>
        }
      } else {
        const loss = custom_nominal - max_thickness
        let loss_percentage = (loss / custom_nominal) * 100
        loss_percentage = loss_percentage.toFixed(2)
        if (loss_percentage > 0) {
          return <b>{loss_percentage}</b>
        } else {
          return <b>0</b>
        }
      }
    }
  }

  handleWallLossButton(percentage) {
    const nominal = this.props.estimated_nominal
    const max_thickness = this.props.maximum_thickness
    const custom_nominal = this.props.custom_nominal

    if (nominal === '' || nominal === null) {
      console.log('No nominal for wall loss button')
    } else {
      if (custom_nominal === '' || custom_nominal === 0) {
        let max_thickness = nominal - (percentage / 100) * nominal
        max_thickness = max_thickness.toFixed(3)
        this.props.refresh(max_thickness)
      } else {
        let max_thickness = custom_nominal - (percentage / 100) * custom_nominal
        max_thickness = max_thickness.toFixed(3)
        this.props.refresh(max_thickness)
      }
    }
  }

  render() {
    let minimum = this.load_minimum_thickness()
    let maximum = this.load_maximum_thickness()
    let range_slider = this.load_range_slider()
    let initial_maximum = this.load_initial_maximum()
    let initial_minimum = this.load_initial_minimum()
    let wall_loss = this.load_wall_loss()
    return (
      <div>
        <Container className='Thickness-card' fluid>
          <header className='Thickness-card-header'>
            <h5 className='thickness-filter text-primary'>Thickness Filter</h5>
            <Row>
              <Col>
                <label>Min</label>
                <input
                  id='min-thickness'
                  xs={6}
                  className='thickness-input Col-main'
                  type='number'
                  value={minimum}
                  min={initial_minimum}
                  step={0.001}
                  onChange={this.handleLowThickness}></input>
                <p className='wall-loss text-primary'>Wall Loss %:</p>
              </Col>
              <Col>
                <label>Max</label>

                <input
                  id='max-thickness'
                  xs={6}
                  className='thickness-input Col-main'
                  type='number'
                  value={maximum}
                  max={initial_maximum}
                  step={0.001}
                  onChange={this.handleHighThickness}></input>
                {wall_loss}
              </Col>
            </Row>
            <div className='wall-loss-button-div'>
              <Button
                className='wall-loss-button bg-primary'
                onClick={this.handleWallLossButton.bind(this, 10)}>
                10% Wall Loss
              </Button>
            </div>
            <div className='wall-loss-button-div'>
              <Button
                className='wall-loss-button bg-primary'
                onClick={this.handleWallLossButton.bind(this, 25)}>
                25% Wall Loss
              </Button>
            </div>
            <div className='wall-loss-button-div'>
              <Button
                className='wall-loss-button bg-primary'
                onClick={this.handleWallLossButton.bind(this, 50)}>
                50% Wall Loss
              </Button>
            </div>
          </header>
        </Container>
      </div>
    )
  }
}
