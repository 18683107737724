import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import App from './App'
import { Auth0Audience, Auth0ClientId, Auth0Url } from './api/helpers'
import './index.css'
import './index.scss'

ReactDOM.render(
  <Auth0Provider
    domain={Auth0Url()}
    clientId={Auth0ClientId()}
    redirectUri={window.location.origin}
    audience={Auth0Audience()}>
    <App />
  </Auth0Provider>,
  document.getElementById('root')
)
