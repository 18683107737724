import React from 'react'
import LoginButton from '../components/LoginButton'
import { ReactComponent as GeckoLogo } from 'assets/gecko-logo.svg'

function LoggedOutContent() {
  return (
    <div className='flex items-center justify-center min-h-full '>
      <div className='w-full max-w-md'>
        <GeckoLogo className='mb-4 h-2/3' />
        <div>
          <LoginButton />
        </div>
      </div>
    </div>
  )
}

export default LoggedOutContent
