const { REACT_APP_ENVIRONMENT } = process.env

/*
DON'T PUT SENSITIVE VALUES IN THIS FILE
 */

const PROD_ENV = 'prod'

const AUTH0_CLIENT_ID_DEV = 'RI6aaKJaOhTcHcj1kdAmW5Xq3EuLO29z'
const AUTH0_CLIENT_ID_PROD = 'PtlZixDcuiwTsajbwSRPgvBsLfrzV4OS'

export const Auth0ClientId = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_CLIENT_ID_PROD : AUTH0_CLIENT_ID_DEV
}

const AUTH0_URL_DEV = 'dev-7ng94g85.us.auth0.com'
const AUTH0_URL_PROD = 'geckorobotics.us.auth0.com'

export const Auth0Url = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_URL_PROD : AUTH0_URL_DEV
}

const ORACLE_3D_BACKEND_URL_DEV = 'https://oracle-3d-backend-service.dev.cloud.geckorobotics.com/api/v1'
const ORACLE_3D_BACKEND_URL_PROD = 'https://oracle-3d-backend-service.cloud.geckorobotics.com/api/v1'

const OBJECT_URL_DEV = 'https://object-service.dev.cloud.geckorobotics.com/api/v1'
const OBJECT_URL_PROD = 'https://object-service.cloud.geckorobotics.com/api/v1'

export const OracleBackendUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV
    ? ORACLE_3D_BACKEND_URL_PROD
    : ORACLE_3D_BACKEND_URL_DEV
}

export const ObjectBackendUrl = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV
    ? OBJECT_URL_PROD
    : OBJECT_URL_DEV
}

const AUTH0_AUDIENCE_DEV = 'dev.cloud.geckorobotics.com'
const AUTH0_AUDIENCE_PROD = 'cloud.geckorobotics.com'

export const Auth0Audience = () => {
  return REACT_APP_ENVIRONMENT === PROD_ENV ? AUTH0_AUDIENCE_PROD : AUTH0_AUDIENCE_DEV
}
